body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-page-header, .ant-page-header-compact {
  display: none;
}

.isClaimableComplain {
  /*border: 1px solid rgb(254, 69, 69);*/
  color: red;
  /*background-color: rgba(254, 69, 69, .5);*/
}